import { createUseStyles } from 'react-jss';
import { Link, navigate } from 'gatsby';
import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const useStyles = createUseStyles(theme => ({
  contactUsMessage: {
    fontSize: '2rem',
    margin: '1rem',
    textAlign: 'center',
  },
  finePrint: {
    fontSize: '0.75rem',
  },
  featureCardHeader: {
    background: '#009688',
    color: 'white',
    padding: '1rem',
    textAlign: 'center',
  },
  featureCardPrice: {
    fontSize: '2rem',
  },
  featureCardPerMonth: {
    color: 'white',
    fontSize: '1rem',
    verticalAlign: 'sub',
  },
  featureCardTitle: {
    fontSize: '2rem',
    marginBottom: '1rem',
  },
  modulesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  moduleCardContent: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1.5',
    padding: '1rem',
  },
  header: {
    color: 'inherit',
    textAlign: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const styleFeatureCard = {
  display: 'flex',
  flex: '1 1 200px',
  flexDirection: 'column',
  maxWidth: '600px',
  padding: '0',
  width: '40%',
};

const Pricing = (props) => {
  const classes = useStyles(props);
  return (
    <Layout>
      <SEO title="Pricing" />
      <h1 className={classes.header}>
        Pricing
      </h1>
      <div className={classes.main}>
        <div className={classes.modulesContainer}>
          <Card style={styleFeatureCard}>
            <div className={classes.featureCardHeader}>
              <div className={classes.featureCardTitle}>
                These Features and One Module
              </div>
              <span className={classes.featureCardPrice}>
                $149.99
                &nbsp;
              </span>
              <span className={classes.featureCardPerMonth}>
                / month
              </span>
            </div>
            <div className={classes.moduleCardContent}>
              <ul>
                <li>
                  Feedback and Communication
                </li>
                <li>
                  Staff Performance Notes and History
                </li>
                <li>
                  Manager Notes
                </li>
                <li>
                  Broadcast Announcements
                </li>
                <li>
                  Event Calendar
                </li>
                <li>
                  Instant Alerts
                </li>
                <li>
                  Historical Data
                </li>
                <li>
                  Custom Reports
                </li>
                <li>
                  Single Location
                </li>
                <li>
                  Up to 25 Staff
                </li>
                <li>
                  Data Import (Spreadsheet / Other Source)
                </li>
                <li>
                  Business Hours Support
                </li>
              </ul>
              <span className={classes.finePrint}>
                * Core is required for other modules. Setup fee is not included.
              </span>
              <br />
              <Button onClick={() => navigate('/features#staff')}>
                SEE FEATURE LIST
              </Button>
            </div>
          </Card>
        </div>
        <h1 className={classes.header}>
          Modules
        </h1>
        <div className={classes.modulesContainer}>
          <Card style={styleFeatureCard}>
            <div className={classes.featureCardHeader}>
              <div className={classes.featureCardTitle}>
                Food Prep &amp; Waste
              </div>
              <span className={classes.featureCardPrice}>
                $50
                &nbsp;
              </span>
              <span className={classes.featureCardPerMonth}>
                / month
              </span>
            </div>
            <div className={classes.moduleCardContent}>
              <ul>
                <li>
                  Demand Calendar
                </li>
                <li>
                  Automatic Food Prep List
                </li>
                <li>
                  Daily Usage Tracking
                </li>
                <li>
                  Recipe Book
                </li>
                <li>
                  Smart Recipe Scaling
                </li>
                <li>
                  Inventory Counting
                </li>
                <li>
                  Waste Tracking
                </li>
              </ul>
              <Button onClick={() => navigate('/features#prep')}>
                SEE FEATURE LIST
              </Button>
            </div>
          </Card>
          <Card style={styleFeatureCard}>
            <div className={classes.featureCardHeader}>
              <div className={classes.featureCardTitle}>
                Checkouts &amp; Drawer Count
              </div>
              <span className={classes.featureCardPrice}>
                $50
                &nbsp;
              </span>
              <span className={classes.featureCardPerMonth}>
                / month
              </span>
            </div>
            <div className={classes.moduleCardContent}>
              <ul>
                <li>
                  Multiple Position Staff Checkout
                </li>
                <li>
                  Tip Out Management
                </li>
                <li>
                  Cash Drawer Count
                </li>
                <li>
                  Custom Closeouts
                </li>
                <li>
                  Ledger / Safe Tracking
                </li>
                <li>
                  Fraud Detection
                </li>
                <li>
                  Deposit Verification
                </li>
              </ul>
              <Button onClick={() => navigate('/features#checkouts')}>
                SEE FEATURE LIST
              </Button>
            </div>
          </Card>
        </div>
        <div className={classes.contactUsMessage}>
          Please
          {' '}
          <Link to="/contact">
            Contact Us
          </Link>
          {' '}
          to get started
        </div>
      </div>
    </Layout>
  );
};

export default Pricing;
